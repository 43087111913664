.slider {
	position: relative;
	width: 100%;
	height: 400px;
	overflow: hidden
}

.slider a.previousButton, .slider a.nextButton {
	font-size: 22px;
	line-height: 0;
	display: block;
	position: absolute;
	left: 50%;
	-webkit-transform: translateX(-50%);
	        transform: translateX(-50%);
	-webkit-transition: all .3s ease;
	transition: all .3s ease;
	z-index: 1;
	color: #333;
	padding: 10px
}

.slider a.previousButton:not(.disabled):hover, .slider a.nextButton:not(.disabled):hover {
	-webkit-transform: translateX(-50%) scale(1.25);
	        transform: translateX(-50%) scale(1.25);
	cursor: pointer;
}

.slider a.previousButton {
	top: 20px;
}

.slider a.nextButton {
	bottom: 20px;
}

.slide {
	width: 100%;
	height: 100%;
	position: absolute;
	overflow: hidden
}

.slide.hidden {
	visibility: hidden;
}

.slide.previous {
	top: -100%;
}

.slide.current {
	top: 0;
}

.slide.next {
	top: 100%;
}

.slide.animateIn,
	.slide.animateOut {
	-webkit-transition: all 2s ease;
	transition: all 2s ease;
}

.slide.animateIn.previous,
		.slide.animateIn.next {
	top: 0;
	visibility: visible;
}

.slide.animateOut.previous {
	top: 100%;
}

.slide.animateOut.next {
	top: -100%;
}


#root .content .home {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column; }
  #root .content .home .slider {
    height: 375px; }
    #root .content .home .slider a.previousButton,
    #root .content .home .slider a.nextButton {
      display: none; }
    #root .content .home .slider .slide {
      background-position-y: center;
      background-size: cover; }
  #root .content .home .content-wrapper {
    padding: 20px;
    display: -webkit-flex;
    display: flex; }
    #root .content .home .content-wrapper .block-title {
      font-size: 18px;
      text-align: center;
      margin-bottom: 10px; }
      #root .content .home .content-wrapper .block-title a {
        text-decoration: none; }
        #root .content .home .content-wrapper .block-title a b {
          font-weight: bold;
          color: #000000; }
    #root .content .home .content-wrapper p {
      margin-top: auto;
      margin-bottom: 0; }
    #root .content .home .content-wrapper #index-left,
    #root .content .home .content-wrapper #index-right,
    #root .content .home .content-wrapper #index-mid {
      height: 275px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      -webkit-align-items: center;
              align-items: center; }
    #root .content .home .content-wrapper #index-left {
      width: 279px;
      border-right: 1px solid #c5c6c6;
      padding: 0px 20px 0px 20px; }
    #root .content .home .content-wrapper #index-left h2 {
      color: #333;
      font-size: 28px;
      line-height: 28px;
      padding: 20px 0px 20px 0px; }
    #root .content .home .content-wrapper #index-mid,
    #root .content .home .content-wrapper #index-right {
      width: 279px;
      border-right: 1px solid #c5c6c6;
      padding: 0px 20px 0px 20px; }
    #root .content .home .content-wrapper #index-right {
      border: 0px; }
    #root .content .home .content-wrapper #index-mid h2,
    #root .content .home .content-wrapper #index-right h2 {
      color: #333;
      font-size: 28px;
      line-height: 28px;
      padding: 20px 0px 20px 0px; }
    #root .content .home .content-wrapper #index-mid h2 img,
    #root .content .home .content-wrapper #index-right h2 img {
      margin: -20px 0px 0px 0px; }

