#root .content .home {
  width: 100%;
  display: flex;
  flex-direction: column; }
  #root .content .home .slider {
    height: 375px; }
    #root .content .home .slider a.previousButton,
    #root .content .home .slider a.nextButton {
      display: none; }
    #root .content .home .slider .slide {
      background-position-y: center;
      background-size: cover; }
  #root .content .home .content-wrapper {
    padding: 20px;
    display: flex; }
    #root .content .home .content-wrapper .block-title {
      font-size: 18px;
      text-align: center;
      margin-bottom: 10px; }
      #root .content .home .content-wrapper .block-title a {
        text-decoration: none; }
        #root .content .home .content-wrapper .block-title a b {
          font-weight: bold;
          color: #000000; }
    #root .content .home .content-wrapper p {
      margin-top: auto;
      margin-bottom: 0; }
    #root .content .home .content-wrapper #index-left,
    #root .content .home .content-wrapper #index-right,
    #root .content .home .content-wrapper #index-mid {
      height: 275px;
      display: flex;
      flex-direction: column;
      align-items: center; }
    #root .content .home .content-wrapper #index-left {
      width: 279px;
      border-right: 1px solid #c5c6c6;
      padding: 0px 20px 0px 20px; }
    #root .content .home .content-wrapper #index-left h2 {
      color: #333;
      font-size: 28px;
      line-height: 28px;
      padding: 20px 0px 20px 0px; }
    #root .content .home .content-wrapper #index-mid,
    #root .content .home .content-wrapper #index-right {
      width: 279px;
      border-right: 1px solid #c5c6c6;
      padding: 0px 20px 0px 20px; }
    #root .content .home .content-wrapper #index-right {
      border: 0px; }
    #root .content .home .content-wrapper #index-mid h2,
    #root .content .home .content-wrapper #index-right h2 {
      color: #333;
      font-size: 28px;
      line-height: 28px;
      padding: 20px 0px 20px 0px; }
    #root .content .home .content-wrapper #index-mid h2 img,
    #root .content .home .content-wrapper #index-right h2 img {
      margin: -20px 0px 0px 0px; }
